import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  AppBar, withStyles, Box, Typography, Toolbar, MenuItem, Menu, Button,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import redirectLinks from '../../constants/redirectLinks';

// #region css
const styles = (theme) => ({
  root: {
    marginTop: '120px',
    marginBottom: '120px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '90px',
      marginBottom: '70px',
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
});
// #endregion

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  }

  onClickLogout = () => {
    localStorage.clear();
    window.location.href = redirectLinks.LOGIN;
  }

  onClickChangePassword = () => {
    window.location.href = redirectLinks.CHANGE_PASSWORD;
  }

  render() {
    const { classes, common } = this.props;
    const { user } = common;
    const {
      open,
      anchorEl,
    } = this.state;
    const {
      children,
    } = this.props;

    return (
      <div className={classes.flexGrow}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.flexGrow}>
              <a href="/" className={classes.link}>ENJOY LIFE CLUB</a>
            </Typography>
            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleMenu}
              >
                <Box fontSize="h6.fontSize" color="#fff" fontWeight="fontWeightBold">
                  {user && user.user.lastName + user.user.firstName}
                </Box>
                <ExpandMoreIcon style={{ color: '#fff' }} />
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.onClickChangePassword}>パスワード変更</MenuItem>
                <MenuItem onClick={this.onClickLogout}>ログアウト</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <main>
          <Box className={classes.root}>
            {children}
          </Box>
        </main>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({ common: state.common });

export default withStyles(styles)(connect(mapStateToProps)(Header));
