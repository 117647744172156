import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  Button, TextField, Link,
  Grid, Box, Typography, withStyles,
  Container, Paper, LinearProgress, IconButton,
  Collapse,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { actCallApiLogin } from '../../../redux/other/login/action';
import redirectLinks from '../../../constants/redirectLinks';
import elcLogo from '../../../public/assets/elcLogo.png';

// #region css
const styles = (theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: '100%',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  grid: {
    textAlign: 'right',
  },
  container: {
    marginTop: theme.spacing(8),
    lineHeight: '4.5',
  },
  root: {
    padding: theme.spacing(2),
  },
  error: {
    color: '#f50057',
    lineHeight: '1',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});
// #endregion

class Login extends React.Component {
  constructor() {
    super();
    localStorage.clear();
    document.cookie = 'tjwt=; path=/; max-age=0';
    const query = window.location.search;
    this.state = {
      info: {
        teacherCode: '',
        password: '',
      },
      message: '',
      loading: false,
      passwordSuccessOpen: query.indexOf('success') !== -1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      let token = '';
      let message = '';
      if (this.props.users.isError === false) {
        token = this.props.users.token.token;
        document.cookie = `tjwt=${token}; path=/; max-age=604800`;
        this.props.history.push(redirectLinks.BASE);
      } else {
        message = '先生コードまたはパスワードが間違っています';
      }
      this.setStatus(message);
    }
  }

  setStatus(message) {
    const { info } = this.state;
    this.setState({ message, info: { ...info, password: '' }, loading: false });
  }

  setPasswordSuccessOpen = () => {
    this.setState({ passwordSuccessOpen: false });
  }

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.teacherCode || !info.password) {
      return;
    }

    this.props.dispatch(
      actCallApiLogin({
        email: info.teacherCode,
        password: info.password,
      }),
    );
  };

  handleChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [key]: value } });
  };

  render() {
    const { classes } = this.props;
    const {
      info, message, loading, passwordSuccessOpen,
    } = this.state;

    return (
      <Container component="main" maxWidth="xs" className={classes.container}>
        {loading && <LinearProgress /> }
        <Paper variant="outlined" className={classes.root}>
          <div className={classes.paper}>
            <Box className={classes.avatar}>
              <img src={elcLogo} alt="logo" width="100%" />
            </Box>
            <Typography component="h1" variant="h5">先生マイページ</Typography>
            {message && <div className={classes.error}>{message}</div>}

            <Box mb={2} className={classes.form} display={passwordSuccessOpen ? '' : 'none'}>
              <Collapse in={passwordSuccessOpen}>
                <Alert
                  action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={this.setPasswordSuccessOpen}><CloseIcon fontSize="inherit" /></IconButton>
                  }
                >
                  パスワードの変更が完了しました
                </Alert>
              </Collapse>
            </Box>
            <form className={classes.form} onSubmit={this.handleBtnLogin}>
              <TextField
                variant="outlined"
                fullWidth
                id="teacherCode"
                label="先生コード"
                name="teacherCode"
                autoComplete="off"
                autoFocus
                required
                value={info.teacherCode}
                onChange={(event) => this.handleChange(event)}
              />
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="off"
                required
                value={info.password}
                onChange={(event) => this.handleChange(event)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
              >
                ログイン
              </Button>
              <Grid container className={classes.grid}>
                <Grid item xs>
                  <Link href={redirectLinks.FORGOT_PASSWORD} variant="body2">
                    パスワードを忘れた場合
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.vertex-group.co.jp/">VERTEX</Link>
              {' '}
              All Rights Reserved.
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ users: state.users });

export default withStyles(styles)(connect(mapStateToProps)(Login));
