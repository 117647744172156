// #region Type
export const ACTION_TYPE = {
  CALL_API_GET_LESSON_LIST: 'CALL_API_GET_LESSON_LIST',
  GET_LESSON_LIST_SUCCESS: 'GET_LESSON_LIST_SUCCESS',
  SET_LESSON_LIST_CONDITION: 'SET_LESSON_LIST_CONDITION',
};
// #endregion

// #region action
export const actCallApiGetLessonList = (param) => ({
  type: ACTION_TYPE.CALL_API_GET_LESSON_LIST,
  param,
});

export const actGetLessonListSuccess = (data) => ({
  type: ACTION_TYPE.GET_LESSON_LIST_SUCCESS,
  data,
});

export const actSetLessonListCondition = (condition) => ({
  type: ACTION_TYPE.SET_LESSON_LIST_CONDITION,
  condition,
});
// #endregion
