import { COMMON } from './common';

export const REST_URL = COMMON.ROOT_URL;
export const REST_COMMON_URL = COMMON.COMMON_URL;

export const REST_API = {
  USER: {
    LOGIN: `${REST_URL}/authenticate`,
    ME: `${REST_URL}/me`,
  },
  PASSWORD: {
    PASSWORD: `${REST_URL}/change-password`,
  },
  RESET_PASSWORD: {
    FORGOT: `${REST_COMMON_URL}/forgot`,
    CHECK_EXPIRATION: `${REST_COMMON_URL}/check-expiration`,
    RESET: `${REST_COMMON_URL}/reset-password`,
  },
  LESSON: {
    LESSON_LIST: `${REST_URL}/lessons`,
  },
  LESSON_DETAIL: {
    LESSON: `${REST_URL}/lesson-details`,
    LESSON_MEMBERS: `${REST_URL}/lesson-members`,
    UPDATE_STATUS: `${REST_URL}/lessons/{id}`,
    CONNECT_ONLINE_CULTURE: `${REST_URL}/connect-online-cultures`,
  },
};
