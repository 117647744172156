import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles,
  Container, Box,
  TableContainer, Table,
  TableCell, TableBody, TableRow,
  TableHead, Button,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, Select, MenuItem,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  format, subMinutes,
  isAfter, isEqual, isBefore,
} from 'date-fns';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Pagination from '../../../components/atoms/pagination/Pagination';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import redirectLinks from '../../../constants/redirectLinks';
import beginner from '../../../public/assets/beginner.svg';
import {
  actCallApiGetInitLessonDetail,
  actCallApiGetLessonMember,
  actCallApiUpdateStatus,
} from '../../../redux/lesson/lessonDetail/action';
import SuccessSnackbar from '../../../components/atoms/successSnackbar/SuccessSnackbar';

const styles = (theme) => ({
  paper: {
    width: 'fit-content',
  },
  emptyTableCell: {
    textAlign: 'center',
    border: 'solid 1px #DDD',
    fontSize: '1rem',
  },
  td: {
    border: 'solid 1px #DDD',
    fontSize: '1rem',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  formControl: {
    minWidth: 200,
  },
  beginnerImg: {
    width: '30px',
    height: 'auto',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '20px',
    },
  },
  textCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});

class LessonDetailChange extends Component {
  constructor(props) {
    super(props);

    if (!this.props.location.state.id) {
      window.location.href = redirectLinks.BASE;
    }

    const page = 0;
    const records = 10;
    let { id, contentId } = 0;

    if (this.props.location.state.id) {
      id = this.props.location.state.id;
      contentId = this.props.location.state.contentId;
      this.props.dispatch(actCallApiGetInitLessonDetail(
        {
          id, contentId, page, records,
        },
      ));
    }

    this.state = {
      header: {
        targetDate: '',
        week: '',
      },
      members: [],
      contentId,
      id,
      page,
      records,
      totalElements: 0,
      isSuccess: false,
      successMessage: '',
      isOpenDialog: false,
      selectData: {},
      afterStatus: '',
      statuses: [],
      aheadStatuses: [],
      statusMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessonDetail.header !== this.props.lessonDetail.header) {
      if (this.props.lessonDetail.header) {
        this.setHeader(this.props.lessonDetail.header);
      }
    }
    if (prevProps.lessonDetail.members !== this.props.lessonDetail.members) {
      if (this.props.lessonDetail.members) {
        this.setMembers(this.props.lessonDetail.members);
      }
    }
    if (prevProps.lessonDetail.isUpdateSuccess !== this.props.lessonDetail.isUpdateSuccess) {
      if (this.props.lessonDetail.isUpdateSuccess) {
        this.updateSuccess();
      }
    }
  }

  onDetailClick = (_, row) => {
    this.setState({
      isOpenDialog: true, selectData: row, afterStatus: '', statusMessage: '', isSuccess: false,
    });
  }

  handleDialogClose = () => {
    this.setState({ isOpenDialog: false });
  }

  handleChangePage = (_, newPage) => {
    const { id, contentId, records } = this.state;
    this.props.dispatch(actCallApiGetLessonMember({
      page: newPage, records, id, contentId,
    }));
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    const { id, contentId, page } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.dispatch(actCallApiGetLessonMember({
      page, records: rowsPerPage, id, contentId,
    }));
    this.setState({ records: rowsPerPage });
  }

  onAttendanceClick = (_, row) => {
    this.props.dispatch(actCallApiUpdateStatus({ id: row.id, status: 2 }));
    this.setState({ successMessage: `${row.name}さんを出席済みにしました` });
  }

  onBackClick = () => {
    window.location.href = redirectLinks.BASE;
  }

  onSuccessClose = () => {
    this.setState({ isSuccess: false });
  }

  handleStatusChange = (event) => {
    let statusMessage = '';
    const afterStatus = event.target.value;
    if (afterStatus === '') {
      statusMessage = '選択してください';
    }
    this.setState({ afterStatus, statusMessage });
  }

  handleDialogUpdate = () => {
    const { afterStatus, selectData } = this.state;
    let statusMessage = '';
    let successMessage = '';
    let isOpenDialog = false;
    if (afterStatus === '') {
      statusMessage = '選択してください';
      isOpenDialog = true;
    } else {
      successMessage = `${selectData.name}さんのステータス変更が完了しました`;
      this.props.dispatch(actCallApiUpdateStatus({ id: selectData.id, status: afterStatus }));
    }
    this.setState({ successMessage, statusMessage, isOpenDialog });
  }

  handleClickZoomOpen = () => {
    const setUrl = `${redirectLinks.CONNECT_ONLINE_CULTURE}/?key=${this.state.id}`;
    this.props.history.push({
      pathname: setUrl,
    });
  };

  setHeader(datas) {
    this.setState({
      header: datas.lesson,
      statuses: datas.statuses || [],
      aheadStatuses: datas.aheadStatuses || [],
    });
  }

  setMembers(data) {
    this.setState({ members: data.content, totalElements: data.totalElements });
  }

  setFormatDate(date) {
    return !date ? '' : format(new Date(date), 'yyyy年MM月dd日', { locale: this.locale });
  }

  updateSuccess() {
    const {
      id, contentId, page, records,
    } = this.state;
    this.props.dispatch(actCallApiGetLessonMember({
      page, records, id, contentId,
    }));
    this.setState({ isSuccess: true });
  }

  checkOnlineCulture() {
    const {
      targetDate, startTime, endTime,
      possibleEnterNumber, zoomMainUrl, mstContentId,
      openZoomDisplayed,
    } = this.state.header;

    if (mstContentId !== 17 || !zoomMainUrl || !openZoomDisplayed) return false;

    const targetNewDate = subMinutes(new Date(`${targetDate} ${startTime}`), possibleEnterNumber);
    const targetEndDate = new Date(`${targetDate} ${endTime}`);
    const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
    const result = (isAfter(today, targetNewDate) && isBefore(today, targetEndDate))
                || isEqual(targetNewDate, today);
    return result;
  }

  render() {
    const { classes } = this.props;
    const {
      header,
      page,
      records,
      totalElements,
      members,
      isSuccess,
      successMessage,
      isOpenDialog,
      selectData,
      statuses,
      aheadStatuses,
      afterStatus,
      statusMessage,
      contentId,
    } = this.state;

    return (
      <Container>
        <Box fontWeight="fontWeightBold" fontSize="h5.fontSize" mb={1}>{`${this.setFormatDate(header.targetDate)} ${header.week}`}</Box>
        <Box border={1} borderColor="#DDD" className={classes.paper} mb={3}>
          <Box display={{ xs: '', sm: 'flex' }} p={{ xs: 2, sm: 3 }}>
            <Box display={{ xs: 'flex', sm: '' }}>
              <Box>
                <Box fontWeight="fontWeightBold" fontSize={17}>開始時間</Box>
                <Box>{header.startTime}</Box>
              </Box>
              <Box ml={4}>
                <Box fontWeight="fontWeightBold" fontSize={17}>終了時間</Box>
                <Box>{header.endTime}</Box>
              </Box>
            </Box>
            <Box display={{ xs: 'flex', sm: '' }} mt={{ xs: 2, sm: 0 }}>
              <Box ml={{ xs: 0, sm: 4 }}>
                <Box fontWeight="fontWeightBold" fontSize={17}>メニュー名</Box>
                <Box>{header.menuName}</Box>
              </Box>
              {contentId !== 2 && (
                <Box ml={4}>
                  <Box fontWeight="fontWeightBold" fontSize={17}>Room</Box>
                  <Box>{header.roomName == null ? '　' : header.roomName}</Box>
                  { this.checkOnlineCulture() && (
                    <Box><Button variant="contained" color="primary" startIcon={<OpenInNew />} onClick={(event) => this.handleClickZoomOpen(event)}>Zoomを開く</Button></Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* <Hidden xsDown> */}
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.td}>
                  <span style={{ display: header.nameDisplayed ? '' : 'none' }}>名前</span>
                </TableCell>
                <TableCell className={classes.td}>性別</TableCell>
                <TableCell className={classes.td}>年齢</TableCell>
                <TableCell className={classes.td}>状態</TableCell>
                <TableCell className={classes.td}>参加回数</TableCell>
                {contentId === 2 && (
                  <TableCell className={classes.td}>打席番号</TableCell>
                )}
                <TableCell className={classes.td} style={{ display: header.regular ? '' : 'none' }}>備考</TableCell>
                <TableCell className={classes.td} style={{ display: header.statusChanged ? '' : 'none' }}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className={classes.td} style={{ whiteSpace: 'nowrap' }}>
                    <Box className={classes.textCenter}>
                      <Box>
                        <Box>{row.nameKana}</Box>
                        <Box>{row.name}</Box>
                      </Box>
                      <img src={beginner} alt="初心者" className={classes.beginnerImg} style={{ display: row.beginner ? '' : 'none' }} />
                    </Box>
                  </TableCell>
                  <TableCell className={classes.td}>{row.gender}</TableCell>
                  <TableCell className={classes.td}>{row.age && `${row.age}歳`}</TableCell>
                  <TableCell className={classes.td}>{row.memberStatusName}</TableCell>
                  <TableCell className={classes.td}>{row.joinCount}</TableCell>
                  {contentId === 2 && (
                    <TableCell className={classes.td}>{row.boxNumber}</TableCell>
                  )}
                  <TableCell className={classes.td} style={{ display: header.regular ? '' : 'none' }}>{row.remarks}</TableCell>
                  <TableCell className={classes.td} style={{ display: header.statusChanged ? '' : 'none' }}>
                    <Box display={row.statusKbn === 3 ? 'none' : 'flex'}>
                      <Box mr={2} display={row.memberStatus === 2 ? 'none' : ''}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={(event) => this.onAttendanceClick(event, row)}
                        >
                          出席にする
                        </Button>
                      </Box>
                      <Box mr={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={(event) => this.onDetailClick(event, row)}
                        >
                          ステータス変更
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {
                members.length < 1 && (
                  <TableRow key={0}>
                    <TableCell className={classes.emptyTableCell} colSpan={6}>
                      予約者はいません。
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Hidden> */}

        {/* <Hidden smUp>
          {members.map((row) => (
            <Box boxShadow={3} mb={3} p={2} key={row.id} className={classes.box}>
              <Box>
                <Box
                fontWeight="fontWeightBold" display={header.nameDisplayed ? '' : 'none'}>名前</Box>
                <Box className={classes.textCenter}>
                  {row.name}
                  <img src={beginner} alt="初心者"
                  className={classes.beginnerImg} style={{ display: row.beginner ? '' : 'none' }} />
                </Box>
              </Box>
              <Box display="flex" mt={2}>
                <Box>
                  <Box fontWeight="fontWeightBold">性別</Box>
                  <Box>{row.gender}</Box>
                </Box>
                <Box ml={3}>
                  <Box fontWeight="fontWeightBold">年齢</Box>
                  <Box>{row.age && `${row.age}歳`}</Box>
                </Box>
              </Box>
              <Box mt={1}>
                <Box fontWeight="fontWeightBold">状態</Box>
                <Box>{row.memberStatusName}</Box>
              </Box>
              <Box mt={1}>
                <Box fontWeight="fontWeightBold">備考</Box>
                <Box>{row.remarks}</Box>
              </Box>
              <Box mt={1}>
                <Box fontWeight="fontWeightBold">参加回数</Box>
                <Box>{row.joinCount}</Box>
              </Box>
              <Box mt={2} display={header.statusChanged ? '' : 'none'}>
                <Box display={row.statusKbn === 3 ? 'none' : 'flex'}>
                  <Box mr={2} display={row.memberStatus === 2 ? 'none' : ''}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={(event) => this.onAttendanceClick(event, row)}
                    >
                      出席にする
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(event) => this.onDetailClick(event, row)}
                    >
                      ステータス変更
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          {
            members.length < 1 && (
              <Box textAlign="center" mt={2}>予約者はいません。</Box>
            )
          }
        </Hidden> */}
        <Pagination
          totalElements={totalElements}
          records={records}
          page={page}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIosIcon />}
          onClick={this.onBackClick}
        >
          一覧へ戻る
        </Button>
        <SuccessSnackbar
          open={isSuccess}
          handleClose={this.onSuccessClose}
          message={successMessage}
        />

        <Dialog
          open={isOpenDialog}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            <Box>次の会員のステータスを変更：</Box>
            <Box>{selectData.name}</Box>
          </DialogTitle>
          <DialogContent>
            <Box my={2}>
              <Box>
                <Box fontWeight="fontWeightBold" mb={1}>変更前</Box>
                <Box>{selectData.memberStatusName}</Box>
              </Box>
              <Box mt={2}>
                <Box fontWeight="fontWeightBold" mb={1}>変更後</Box>
                <Box display={selectData.statusKbn !== 2 ? '' : 'none'}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      id="demo-simple-select-outlined"
                      value={afterStatus}
                      onChange={this.handleStatusChange}
                      error={statusMessage !== ''}
                    >
                      {statuses.map((status) => (
                        <MenuItem value={status.id} key={status.id}>{status.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display={selectData.statusKbn === 2 ? '' : 'none'}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      id="demo-simple-select-outlined"
                      value={afterStatus}
                      onChange={this.handleStatusChange}
                      error={statusMessage !== ''}
                    >
                      {aheadStatuses.map((status) => (
                        <MenuItem value={status.id} key={status.id}>{status.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <FormErrorText>{statusMessage}</FormErrorText>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              キャンセル
            </Button>
            <Button onClick={this.handleDialogUpdate} color="primary">
              変更する
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  lessonDetail: state.lessonDetail,
});

export default withStyles(styles)(connect(mapStateToProps)(LessonDetailChange));
