import { call, put, takeLatest } from 'redux-saga/effects';
import getLessons from '../../../services/lesson/lessonList.service';
import {
  ACTION_TYPE, actGetLessonListSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetLessonList(param) {
  try {
    const result = yield call(getLessons, param.param);
    yield put(actGetLessonListSuccess(result));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallLessonList() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_LESSON_LIST, callApiGetLessonList);
}
