import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import '../public/css/style.css';
import {
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import store from '../store/store';
import Login from './other/login/Login';
import Auth from './common/Auth';
import Loading from '../components/organisms/loading/Loading';
import redirectLinks from '../constants/redirectLinks';
import Header from './common/Header';
import LessonList from './lesson/lessonList/LessonList';
import ChangePassword from './other/changePassword/ChangePassword';
import ResetPassword from './other/resetPassword/ResetPassword';
import ForgotPassword from './other/resetPassword/ForgotPassword';
import LessonDetail from './lesson/lessonDetail/LessonDetail';
import ConnectOnlineCulture from './lesson/connectOnlineCulture/ConnectOnlineCulture';
import ApplicationError from './common/ApplicationError';
import NotFound from './common/NotFound';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#c0d434',
      main: '#B1CA02',
      dark: '#7b8d01',
      contrastText: '#fff',
    },
    secondary: {
      light: '#81dbac',
      main: '#62D298',
      dark: '#44936a',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#E8F0FE',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: '600',
      },
    },
  },
});

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Loading />
        <Router history={createHashHistory()}>
          <Switch>
            <Route exact path={redirectLinks.LOGIN} component={Login} />
            <Route exact path={redirectLinks.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={redirectLinks.FORGOT_PASSWORD} component={ForgotPassword} />
            <Auth>
              <Header>
                <Switch>
                  <Route exact path={redirectLinks.BASE} component={LessonList} />
                  <Route exact path={redirectLinks.CHANGE_PASSWORD} component={ChangePassword} />
                  <Route exact path={redirectLinks.LESSON_DETAIL} component={LessonDetail} />
                  <Route
                    path={redirectLinks.CONNECT_ONLINE_CULTURE}
                    component={ConnectOnlineCulture}
                  />
                  <Route exact path={redirectLinks.SYSTEM_ERROR} component={ApplicationError} />
                  <Route component={NotFound} />
                </Switch>
              </Header>
            </Auth>
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}
