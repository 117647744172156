import { ACTION_TYPE } from './action';

const lessonDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_INIT_LESSON_DETAIL_SUCCESS:
      return {
        ...state,
        header: action.header,
        members: action.members,
        isUpdateSuccess: false,
      };
    case ACTION_TYPE.GET_LESSON_MEMBER_SUCCESS:
      return {
        ...state,
        members: action.members,
        isUpdateSuccess: false,
      };
    case ACTION_TYPE.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
      };
    default:
      return state;
  }
};

export default lessonDetail;
