import { call, put, takeLatest } from 'redux-saga/effects';
import { getLessonDetail, getLessonMembers, updateStatus } from '../../../services/lesson/lessonDetail.service';
import {
  ACTION_TYPE, actGetInitLessonDetailSuccess,
  actGetLessonMemberSuccess, actUpdateStatusSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

function* callApiGetInitLessonDetail({ param }) {
  try {
    const result = yield call(getLessonDetail, { id: param.id, contentId: param.contentId });
    const members = yield call(getLessonMembers, param);
    yield put(actGetInitLessonDetailSuccess(result, members));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiGetLessonMembers(param) {
  try {
    const members = yield call(getLessonMembers, param.param);
    yield put(actGetLessonMemberSuccess(members));
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

function* callApiUpdateStatus(param) {
  try {
    yield call(updateStatus, param.param);
    yield put(actUpdateStatusSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallLessonDetail() {
  yield takeLatest(ACTION_TYPE.CALL_API_INIT_LESSON_DETAIL, callApiGetInitLessonDetail);
  yield takeLatest(ACTION_TYPE.CALL_API_LESSON_MEMBER, callApiGetLessonMembers);
  yield takeLatest(ACTION_TYPE.CALL_API_UPDATE_STATUS, callApiUpdateStatus);
}
