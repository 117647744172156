import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function getBooking(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.LESSON_DETAIL.CONNECT_ONLINE_CULTURE, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
