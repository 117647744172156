const passwordValidation = (password) => {
  if (!password) return '入力してください';
  const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
  if (!regex.test(password)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
  return '';
};

const passwordCheckValidation = (password, passwordCheck) => {
  if (!passwordCheck) return '入力してください';
  const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
  if (!regex.test(passwordCheck)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
  if (password !== passwordCheck) return 'パスワードが一致しません';
  return '';
};

class Validation {
  static formValidate = (type, value, checkValue) => {
    switch (type) {
      case 'password':
        return passwordValidation(value);
      case 'passwordConfirm':
        return passwordCheckValidation(value, checkValue);
      default:
        return '';
    }
  };
}

export default Validation;
