import React, { Component } from 'react';
import {
  format, subMinutes,
  isAfter, isEqual,
} from 'date-fns';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  Container,
  withStyles,
  Box,
  Card,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import redirectLinks from '../../../constants/redirectLinks';
import { actCallApiGetBooking } from '../../../redux/lesson/connectOnlineCulture/action';
import Loading from '../../../components/organisms/loading/Loading';

const styles = () => ({
  button: {
    marginLeft: '10px',
  },
});

class ConnectOnlineCulture extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop();

    const token = document.cookie.replace(/(?:(?:^|.*;\s*)mjwt\s*=\s*([^;]*).*$)|^.*$/, '$1');
    document.cookie = 'c_fz=; path=/; max-age=0';

    const bookingId = this.getParam('key');
    let isError = false;

    if (!bookingId || bookingId === '') {
      this.props.history.push({
        pathname: redirectLinks.BASE,
      });
      isError = true;
    }

    if (!token) {
      this.props.history.push({
        pathname: redirectLinks.LOGIN,
      });
      document.cookie = `c_fz=${bookingId}; path=/; max-age=3600`;
      isError = true;
    }

    this.state = {
      isLoading: true,
      displayType: 2,
      displayMessage: [],
    };

    if (!isError) {
      this.props.dispatch(actCallApiGetBooking({
        id: bookingId,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.connectOnlineCulture.data !== this.props.connectOnlineCulture.data) {
      this.checkOnlineCulture(this.props.connectOnlineCulture.data);
    }
  }

  getParam(name) {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  scrollToTop = () => window.scrollTo(0, 0);

  onBookingListBackClick = () => {
    this.props.history.push(redirectLinks.BASE);
  }

  checkOnlineCulture(bookingData) {
    if (!bookingData || !bookingData.zoomMainUrl) {
      this.setState({
        displayType: 0,
        displayMessage: ['予約されていません。'],
        isLoading: false,
      });
      return;
    }

    const {
      targetedOn, startTime, possibleEnterNumber,
      zoomMainUrl,
    } = bookingData;

    const targetNewDate = subMinutes(new Date(`${targetedOn} ${startTime}`), possibleEnterNumber);
    const today = new Date(format(new Date(), 'yyyy/MM/dd HH:mm'));
    const result = isAfter(today, targetNewDate) || isEqual(targetNewDate, today);

    const setPossibleEnterMsg = `入室可能時間は${format(targetNewDate, 'MM月dd日', { locale: this.locale })} ${format(targetNewDate, 'HH時mm分', { locale: this.locale })}以降です。`;

    this.setState({
      displayType: result ? 2 : 1,
      displayMessage: result ? ['接続しています。', 'このまましばらくお待ちください。'] : [setPossibleEnterMsg],
      isLoading: false,
    });

    if (result) window.location.href = zoomMainUrl;
  }

  render() {
    const {
      isLoading, displayType, displayMessage,
    } = this.state;

    return (
      <div>
        <Loading open={isLoading} />

        <Container>
          <Card variant="outlined">
            <Box my={3} textAlign="center">
              <Box mb={2}>
                { (displayType === 2) && (
                  <Box mt={3}>
                    <Box textAlign="center" fontWeight="fontWeightBold"><CircularProgress /></Box>
                  </Box>
                )}
                <Box my={3} textAlign="center">
                  {displayMessage && displayMessage.map((message) => (
                    <Box mb={2} key={message}>
                      {message}
                    </Box>
                  ))}
                  { (displayType === 0 || displayType === 1) && (
                    <Box>
                      <Button
                        variant="contained"
                        className="custom-button"
                        onClick={this.onBookingListBackClick}
                        startIcon={<ArrowBackIosIcon />}
                      >
                        一覧画面に戻る
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ connectOnlineCulture: state.connectOnlineCulture });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ConnectOnlineCulture)));
