import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export function getLessonDetail(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.LESSON_DETAIL.LESSON, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function getLessonMembers(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.LESSON_DETAIL.LESSON_MEMBERS, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export function updateStatus(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .put(REST_API.LESSON_DETAIL.UPDATE_STATUS, { id: param.id }, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
