import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Route, withRouter } from 'react-router-dom';
import { actCallApiMe, actResetGetMeFlg } from '../../redux/common/action';
import redirectLinks from '../../constants/redirectLinks';

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(actCallApiMe());
  }

  componentDidUpdate() {
    if (this.props.common.isGetMeError === true) {
      this.reset();
    }
  }

  reset() {
    this.props.dispatch(actResetGetMeFlg());
    localStorage.clear();
    window.location.href = redirectLinks.LOGIN;
  }

  render() {
    return (
      <Route>
        {this.props.children}
      </Route>
    );
  }
}

const mapStateToProps = (state) => ({ common: state.common });

export default withRouter(connect(mapStateToProps)(Auth));
