import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Container,
  Box,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import redirectLinks from '../../../constants/redirectLinks';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import {
  actCallApiCheckExpiration, actResetPasswordResetFlg, actCallApiResetPassword,
} from '../../../redux/other/resetPassword/action';
import Validation from '../changePassword/validation';
import elcLogo from '../../../public/assets/elcLogo.png';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  boxRoot: {
    maxWidth: '530px',
    margin: '60px auto 40px',
    boxSizing: 'border-box',
    padding: '20px',
    border: '2px solid #d9d9d9',
    borderRadius: '10px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  attention: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  avatar: {
    width: '100%',
  },
});
// #endregion

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    let urlParamStr = window.location.search;
    let token = '';
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1);
      token = urlParamStr.substring(urlParamStr.indexOf('=') + 1, urlParamStr.length);
    }

    this.state = {
      token,
      isButtonDisabled: true,
      isComplete: false,
      isExpirationOver: false,
      form: {
        password: '',
        passwordConfirm: '',
      },
      message: {
        password: '',
        passwordConfirm: '',
      },
      isError: false,
    };
    this.breadcrumbs = [
      { name: 'パスワードリセット' },
    ];
    this.props.dispatch(actResetPasswordResetFlg());
    this.props.dispatch(actCallApiCheckExpiration({ userType: '2', token }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetPassword.isExpirationFinish
      !== this.props.resetPassword.isExpirationFinish) {
      if (this.props.resetPassword.isExpirationFinish) {
        this.setInit(this.props.resetPassword.isExpirationError);
      }
    }
    if (prevProps.resetPassword.isResetPasswordSuccess
      !== this.props.resetPassword.isResetPasswordSuccess) {
      if (this.props.resetPassword.isResetPasswordSuccess) {
        this.setComplete();
      }
    }
    if (prevProps.resetPassword.isResetPasswordError
        !== this.props.resetPassword.isResetPasswordError) {
      if (this.props.resetPassword.isResetPasswordError) {
        this.setError();
      }
    }
  }

  onChangeClick = () => {
    const { form, token } = this.state;
    const messages = {
      password: Validation.formValidate('password', form.password),
      passwordConfirm: Validation.formValidate('passwordConfirm', form.passwordConfirm, form.password),
    };
    this.setState({ message: messages });
    if (messages.password || messages.passwordConfirm) {
      return;
    }
    this.props.dispatch(actResetPasswordResetFlg());
    this.props.dispatch(actCallApiResetPassword({ userType: '2', token, password: form.password }));
  }

  onPasswordChange = (event) => {
    const { name, value } = event.target;
    const { form } = this.state;

    let isButtonDisabled = false;
    let passwordConfMessage = '';
    const passwordMessage = Validation.formValidate(name, value);
    if (form.passwordConfirm) {
      passwordConfMessage = Validation.formValidate('passwordConfirm', form.passwordConfirm, value);
    }
    if (passwordMessage || passwordConfMessage || !form.passwordConfirm) {
      isButtonDisabled = true;
    }

    this.setState({
      form: {
        ...form,
        [name]: value,
      },
      message: {
        [name]: passwordMessage,
        passwordConfirm: passwordConfMessage,
      },
      isButtonDisabled,
    });
  }

  onConfirmPasswordChange = (event) => {
    const { name, value } = event.target;
    const { message, form } = this.state;
    const messages = {
      ...message,
      [name]: Validation.formValidate(name, value, form.password),
    };
    let isButtonDisabled = false;
    if (messages.password || messages.passwordConfirm) {
      isButtonDisabled = true;
    }
    this.setState(
      {
        isButtonDisabled,
        form: {
          ...form,
          [name]: value,
        },
        message: messages,
      },
    );
  }

  setInit(isExpirationOver) {
    this.setState({ isExpirationOver });
  }

  setComplete() {
    this.setState({ isComplete: true });
  }

  onLoginClick = () => {
    window.location.href = redirectLinks.LOGIN;
  }

  setError() {
    this.setState({ isComplete: true, isError: true });
  }

  render() {
    const { classes } = this.props;
    const {
      isExpirationOver, form, message, isComplete, isButtonDisabled, isError,
    } = this.state;

    return (
      <div className={classes.root}>
        <Container className={classes.boxRoot}>
          <Box className={classes.avatar} mb={3}>
            <img src={elcLogo} alt="logo" width="100%" />
          </Box>
          <Box display={!isComplete && !isExpirationOver ? '' : 'none'}>
            <Box>
              <Box mt={2}>
                <Alert severity="info">
                  <AlertTitle>パスワードのルール</AlertTitle>
                  <Box mb={1} className={classes.attention}>・8文字以上、16文字以下で設定してください</Box>
                  <Box className={classes.attention}>・半角英数字、記号 (! # $ @ . _) のみで入力出来ます</Box>
                </Alert>
              </Box>
              <Box mt={3}>
                <Box fontWeight="fontWeightBold">新しいパスワード</Box>
                <PasswordField
                  password={form.password}
                  name="password"
                  onChange={this.onPasswordChange}
                  isError={message.password !== ''}
                />
                <FormErrorText>{message.password}</FormErrorText>
              </Box>
              <Box mt={3}>
                <Box fontWeight="fontWeightBold">新しいパスワードの確認</Box>
                <PasswordField
                  password={form.passwordConfirm}
                  name="passwordConfirm"
                  onChange={this.onConfirmPasswordChange}
                  isError={message.passwordConfirm !== ''}
                />
                <FormErrorText>{message.passwordConfirm}</FormErrorText>
              </Box>
              <Box textAlign="center" my={3}>
                <Button variant="contained" color="primary" onClick={this.onChangeClick} disabled={isButtonDisabled}>パスワードを変更する</Button>
              </Box>
            </Box>
          </Box>

          <Box mt={3} display={isExpirationOver ? '' : 'none'}>
            <Box textAlign="center" p={1}>
              <Box>
                有効期限が切れています。
              </Box>
              <Box>
                お手数ですが、
                <Link href={redirectLinks.FORGOT_PASSWORD} className={classes.link}>こちら</Link>
                より再度お手続きをお願いいたします。
              </Box>
            </Box>
          </Box>

          <Box mt={3} display={isComplete ? '' : 'none'}>
            <Box textAlign="center" p={1}>
              <Box display={!isError ? '' : 'none'}>
                パスワードリセットが完了しました。
              </Box>
              <Box display={isError ? '' : 'none'}>
                <Box>恐れ入ります。予期せぬエラーが発生しました。</Box>
                <Box>ログイン画面に戻り、再度お手続きをしてください。</Box>
              </Box>
              <Box textAlign="center" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onLoginClick}
                >
                  ログイン画面に戻る
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.vertex-group.co.jp/">VERTEX</Link>
              {' '}
              All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ resetPassword: state.resetPassword });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ResetPassword)));
