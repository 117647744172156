import { REST_API } from '../../constants/urls';
import HttpConnection from '../../utils/http-connection';

export default function getLessons(param) {
  const httpConnection = new HttpConnection();

  return httpConnection
    .get(REST_API.LESSON.LESSON_LIST, param)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
