// #region Type
export const ACTION_TYPE = {
  TOGGLE_LOADING_SCREEN: 'TOGGLE_LOADING_SCREEN',
  CALL_API_GET_ME: 'CALL_API_GET_ME',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_ERROR: 'GET_ME_ERROR',
  RESET_GET_ME_FLG: 'RESET_GET_ME_FLG',
  CALL_API_SERVER_ERROR: 'CALL_API_SERVER_ERROR',
  CALL_API_RESET_SERVER_ERROR: 'CALL_API_RESET_SERVER_ERROR',
};
// #endregion

// #region action
export const actToggleLoadingScreen = (isLoading) => ({
  type: ACTION_TYPE.TOGGLE_LOADING_SCREEN,
  isLoading,
});

export const actCallApiMe = () => ({
  type: ACTION_TYPE.CALL_API_GET_ME,
});

export const actGetMeError = (errorMessage) => ({
  type: ACTION_TYPE.GET_ME_ERROR,
  errorMessage,
});

export const actResetGetMeFlg = () => ({
  type: ACTION_TYPE.RESET_GET_ME_FLG,
});

export const actGetMeSuccess = (data) => ({
  type: ACTION_TYPE.GET_ME_SUCCESS,
  data,
});

export const actCallApiServerError = () => ({
  type: ACTION_TYPE.CALL_API_SERVER_ERROR,
});

export const actCallApiResetServerError = () => ({
  type: ACTION_TYPE.CALL_API_RESET_SERVER_ERROR,
});
// #endregion
