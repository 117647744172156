import React, { Component } from 'react';
import { addMonths, format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles,
  Container, Button, Grid, Box, Hidden,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Chip,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@material-ui/icons';
import Monthpicker from '../../../components/atoms/monthpicker/Monthpicker';
import Pagination from '../../../components/atoms/pagination/Pagination';
import { actCallApiGetLessonList, actSetLessonListCondition } from '../../../redux/lesson/lessonList/action';
import redirectLinks from '../../../constants/redirectLinks';

const styles = (theme) => ({
  textCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRoot: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  spMonthTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  emptyTableCell: {
    textAlign: 'center',
    border: 'solid 1px #DDD',
    fontSize: '1rem',
  },
  td: {
    border: 'solid 1px #DDD',
    fontSize: '1rem',
  },
  openedChip: {
    height: '25px',
    background: '#0070c0',
    color: '#fff',
  },
});

class LessonList extends Component {
  constructor(props) {
    super(props);
    let targetMonth = format(new Date(), 'yyyy/MM');
    let page = 0;
    let records = 10;

    if (this.props.lessonList.condition) {
      const { condition } = this.props.lessonList;
      targetMonth = condition.targetMonth;
      page = condition.page;
      records = condition.records;
    }
    this.props.dispatch(actCallApiGetLessonList({ page, records, targetMonth }));

    this.state = {
      targetMonth,
      totalElements: 0,
      datas: [],
      page,
      records,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lessonList !== this.props.lessonList) {
      if (this.props.lessonList.data) {
        this.setData(this.props.lessonList.data);
      }
    }
  }

  onMonthClick = (_, index) => {
    const { targetMonth } = this.state;
    const value = format(addMonths(new Date(`${targetMonth}/01`), index), 'yyyy/MM');
    this.props.dispatch(actCallApiGetLessonList({ page: 0, records: 10, targetMonth: value }));
    this.setState({ targetMonth: value });
  };

  handleDateChange = (_, value) => {
    this.props.dispatch(actCallApiGetLessonList({ page: 0, records: 10, targetMonth: value }));
    this.setState({ targetMonth: value });
  };

  handleChangePage = (_, newPage) => {
    const { targetMonth, records } = this.state;
    this.props.dispatch(actCallApiGetLessonList({ page: newPage, records, targetMonth }));
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    const { targetMonth, page } = this.state;
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.dispatch(actCallApiGetLessonList({ page, records: rowsPerPage, targetMonth }));
    this.setState({ records: rowsPerPage });
  }

  onDetailClick = (_, row) => {
    const { targetMonth, records, page } = this.state;
    this.props.dispatch(actSetLessonListCondition({ page, records, targetMonth }));

    this.props.history.push({
      pathname: redirectLinks.LESSON_DETAIL,
      state: {
        id: row.id,
        contentId: row.contentId,
      },
    });
  }

  setData(data) {
    this.setState({ datas: data.content || [], totalElements: data.totalElements || 0 });
  }

  render() {
    const { classes } = this.props;
    const {
      targetMonth, datas, records, page, totalElements,
    } = this.state;

    return (
      <Container>
        <Box my={3}>
          <Grid container>
            <Grid item xs align="left" className={classes.textCenter}>
              <Button
                color="primary"
                startIcon={<ArrowBackIosIcon />}
                className={classes.buttonRoot}
                onClick={(event) => this.onMonthClick(event, -1)}
              >
                {format(addMonths(new Date(`${targetMonth}/01`), -1), 'y年M月')}
              </Button>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={5} className={classes.textCenter}>
                <Monthpicker
                  value={targetMonth}
                  handleChange={this.handleDateChange}
                  variant="inline"
                />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={4} className={classes.textCenter}>
                <Box className={classes.spMonthTitle}>{format(new Date(`${targetMonth}/01`), 'y年M月')}</Box>
              </Grid>
            </Hidden>
            <Grid item xs align="right" className={classes.textCenter}>
              <Button
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                className={classes.buttonRoot}
                onClick={(event) => this.onMonthClick(event, 1)}
              >
                {format(addMonths(new Date(`${targetMonth}/01`), 1), 'y年M月')}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: '130px' }} className={classes.td}>日時</TableCell>
                  <TableCell style={{ minWidth: '130px' }} className={classes.td}>メニュー</TableCell>
                  <TableCell style={{ minWidth: '65px' }} className={classes.td}>状態</TableCell>
                  <TableCell className={classes.td}>予約数</TableCell>
                  <TableCell className={classes.td}>実績数</TableCell>
                  <TableCell className={classes.td}> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row" className={classes.td}>
                      { row.bookingStatusName === '開催確定' && (
                        <Box mb={1}><Chip label="開講" className={classes.openedChip} /></Box>
                      )}
                      <>
                        {`${row.targetDate} (${row.weekName}) ${row.startTime}～${row.endTime}`}
                      </>
                    </TableCell>
                    <TableCell className={classes.td}>{row.menuName}</TableCell>
                    <TableCell className={classes.td}>{row.bookingStatusName}</TableCell>
                    <TableCell className={classes.td}>{row.reservations}</TableCell>
                    <TableCell className={classes.td}>{row.achievements}</TableCell>
                    <TableCell className={classes.td}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(event) => this.onDetailClick(event, row)}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {
                  datas.length < 1 && (
                    <TableRow key={0}>
                      <TableCell className={classes.emptyTableCell} colSpan={6}>
                        講義がありません
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            totalElements={totalElements}
            records={records}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  lessonList: state.lessonList,
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(LessonList)));
