// #region Type
export const ACTION_TYPE = {
  CALL_API_LOGIN: 'CALL_API_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
};
// #endregion

// #region action
export const actCallApiLogin = (userInfo) => ({
  type: ACTION_TYPE.CALL_API_LOGIN,
  userInfo,
});

export const actLoginError = (errorMessage) => ({
  type: ACTION_TYPE.LOGIN_ERROR,
  errorMessage,
});

export const actLoginSuccess = (token) => ({
  type: ACTION_TYPE.LOGIN_SUCCESS,
  token,
});
// #endregion
