import { ACTION_TYPE } from './action';

const lessonList = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_LESSON_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case ACTION_TYPE.SET_LESSON_LIST_CONDITION:
      return {
        ...state,
        condition: action.condition,
      };
    default:
      return state;
  }
};

export default lessonList;
