import { combineReducers } from 'redux';
import users from './other/login/reducer';
import common from './common/reducer';
import changePassword from './other/changePassword/reducer';
import resetPassword from './other/resetPassword/reducer';
import lessonList from './lesson/lessonList/reducer';
import lessonDetail from './lesson/lessonDetail/reducer';
import connectOnlineCulture from './lesson/connectOnlineCulture/reducer';

const appReducer = combineReducers({
  common,
  users,
  changePassword,
  resetPassword,
  lessonList,
  lessonDetail,
  connectOnlineCulture,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
