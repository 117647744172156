import React from 'react';
import { TablePagination } from '@material-ui/core';

export default function Pagination(props) {
  const {
    totalElements,
    records,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, 100]}
      component="div"
      count={totalElements || 0}
      rowsPerPage={records}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelRowsPerPage="1 ページあたりの行数："
      labelDisplayedRows={({ from, to, count }) => `全${count}件中 ${from}件から ${to}件までを表示中`}
    />
  );
}
