import React from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Box,
  Button,
} from '@material-ui/core';
import redirectLinks from '../../constants/redirectLinks';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    lineHeight: 4,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 3,
    },
  },
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

export default function ApplicationError() {
  const classes = useStyles();

  const onClick = () => {
    window.location.href = redirectLinks.BASE;
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper variant="outlined" className={classes.root}>
        <Box fontWeight="fontWeightBold" fontSize={{ xs: 20, sm: 30 }} textAlign="center">予期せぬエラーが発生しました</Box>
        <Box fontSize={{ xs: 12, sm: 18 }} textAlign="center">恐れ入りますがTOPに戻り、操作をやり直してください。</Box>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={onClick}
          >
            TOPに戻る
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
