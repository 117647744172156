import { call, put, takeLatest } from 'redux-saga/effects';
import update from '../../../services/other/changePassword.service';
import {
  ACTION_TYPE,
  actChangePasswordSuccess,
} from './action';
import { actCallApiServerError } from '../../common/action';

/**
 * 更新処理
 */
function* callApiChangePassword(param) {
  try {
    yield call(update, param.param);
    yield put(actChangePasswordSuccess());
  } catch (error) {
    yield put(actCallApiServerError(error));
  }
}

/**
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallPasswordUpdate() {
  yield takeLatest(ACTION_TYPE.CALL_API_CHANGE_PASSWORD, callApiChangePassword);
}
