import { ACTION_TYPE } from './action';

const lessonDetail = (state = {}, action) => {
  switch (action.type) {
    case ACTION_TYPE.CALL_API_INIT_GET_BOOKING_SUCCESS:
      return {
        ...state,
        data: action.datas,
      };
    default:
      return state;
  }
};

export default lessonDetail;
