// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_LESSON_DETAIL: 'CALL_API_INIT_LESSON_DETAIL',
  GET_INIT_LESSON_DETAIL_SUCCESS: 'GET_INIT_LESSON_DETAIL_SUCCESS',
  CALL_API_LESSON_MEMBER: 'CALL_API_LESSON_MEMBER',
  GET_LESSON_MEMBER_SUCCESS: 'GET_LESSON_MEMBER_SUCCESS',
  CALL_API_UPDATE_STATUS: 'CALL_API_UPDATE_STATUS',
  UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
};
// #endregion

// #region action
export const actCallApiGetInitLessonDetail = (param) => ({
  type: ACTION_TYPE.CALL_API_INIT_LESSON_DETAIL,
  param,
});

export const actGetInitLessonDetailSuccess = (header, members) => ({
  type: ACTION_TYPE.GET_INIT_LESSON_DETAIL_SUCCESS,
  header,
  members,
});

export const actCallApiGetLessonMember = (param) => ({
  type: ACTION_TYPE.CALL_API_LESSON_MEMBER,
  param,
});

export const actGetLessonMemberSuccess = (members) => ({
  type: ACTION_TYPE.GET_LESSON_MEMBER_SUCCESS,
  members,
});

export const actCallApiUpdateStatus = (param) => ({
  type: ACTION_TYPE.CALL_API_UPDATE_STATUS,
  param,
});

export const actUpdateStatusSuccess = () => ({
  type: ACTION_TYPE.UPDATE_STATUS_SUCCESS,
});

// #endregion
