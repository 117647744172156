// #region Type
export const ACTION_TYPE = {
  CALL_API_INIT_GET_BOOKING: 'CALL_API_INIT_GET_BOOKING',
  CALL_API_INIT_GET_BOOKING_SUCCESS: 'CALL_API_INIT_GET_BOOKING_SUCCESS',
};
// #endregion

// #region action
export const actCallApiGetBooking = (param) => ({
  type: ACTION_TYPE.CALL_API_INIT_GET_BOOKING,
  param,
});

export const actCallApiGetBookingSuccess = (datas) => ({
  type: ACTION_TYPE.CALL_API_INIT_GET_BOOKING_SUCCESS,
  datas,
});

// #endregion
