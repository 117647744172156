import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {
  withStyles,
  Button,
  Container,
  Box,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import { actCallApiChangePassword } from '../../../redux/other/changePassword/action';
import { actResetGetMeFlg } from '../../../redux/common/action';
import Validation from './validation';
import PasswordField from '../../../components/atoms/passwordFeild/PasswordField';
import redirectLinks from '../../../constants/redirectLinks';

const styles = () => ({
  cancelBtn: {
    marginRight: '15px',
  },
});

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordCheck: '',
      passwordMessage: '',
      passwordCheckMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changePassword !== this.props.changePassword) {
      if (this.props.changePassword.isUpdateSuccess === true) {
        this.logout();
      }
    }
  }

  onPasswordChange = (event) => {
    const { passwordCheck } = this.state;

    this.setState({
      password: event.target.value,
      passwordMessage: Validation.formValidate('password', event.target.value, null),
    });

    if (passwordCheck) {
      this.setState({
        passwordCheckMessage: Validation.formValidate('passwordCheck', event.target.value, passwordCheck),
      });
    }
  }

  onPasswordCheckChange = (event) => {
    const { password } = this.state;

    this.setState({
      passwordCheck: event.target.value,
      passwordCheckMessage: Validation.formValidate('passwordCheck', password, event.target.value),
    });
  }

  onSave = () => {
    const {
      password,
      passwordCheck,
    } = this.state;

    const passwordMessage = Validation.formValidate('password', password, null);
    const passwordCheckMessage = Validation.formValidate('passwordCheck', password, passwordCheck);

    if (!passwordMessage && !passwordCheckMessage) {
      this.props.dispatch(
        actCallApiChangePassword(password),
      );
    }

    this.setState({
      passwordMessage,
      passwordCheckMessage,
    });
  }

  logout() {
    this.props.dispatch(actResetGetMeFlg());
    localStorage.clear();
    window.location.href = redirectLinks.LOGIN;
  }

  render() {
    const {
      password,
      passwordCheck,
      passwordMessage,
      passwordCheckMessage,
    } = this.state;

    // const { classes } = this.props;

    return (
      <Container maxWidth="sm">
        <Box fontWeight="fontWeightBold" fontSize="h6.fontSize" textAlign="center">
          パスワードの変更を行います
        </Box>
        <Box mt={1}>
          <Alert severity="warning">パスワードを変更すると自動でログアウトします</Alert>
        </Box>
        <Box mt={1}>
          <Alert severity="info">
            <AlertTitle>パスワードの安全度</AlertTitle>
            <Box mb={1}>・8文字以上、16文字以下で設定してください</Box>
            <Box>・半角英数字、記号(! # $ @ . _)のみで入力出来ます</Box>
          </Alert>
        </Box>
        <Box>
          <Box mt={2}>
            <Box fontWeight="fontWeightBold" m={1} fontSize={17}>新しいパスワード</Box>
            <PasswordField
              password={password}
              onChange={this.onPasswordChange}
              isError={passwordMessage !== ''}
            />
            <FormErrorText error>{passwordMessage}</FormErrorText>
          </Box>
          <Box mt={2} mb={1}>
            <Box fontWeight="fontWeightBold" m={1} fontSize={17}>新しいパスワードの確認</Box>
            <PasswordField
              password={passwordCheck}
              onChange={this.onPasswordCheckChange}
              isError={passwordCheckMessage !== ''}
            />
            <FormErrorText error>{passwordCheckMessage}</FormErrorText>
          </Box>
        </Box>
        <Box textAlign="center" mt={3}>
          <Button onClick={this.onSave} variant="contained" color="primary">パスワードを変更</Button>
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  changePassword: state.changePassword,
});

export default withStyles(styles)(connect(mapStateToProps)(ChangePassword));
