import { all } from 'redux-saga/effects';
import watchCallLogin from './other/login/saga';
import watchCallCommon from './common/saga';
import watchCallPasswordUpdate from './other/changePassword/saga';
import watchCallResetPassword from './other/resetPassword/saga';
import watchCallLessonList from './lesson/lessonList/saga';
import watchCallLessonDetail from './lesson/lessonDetail/saga';
import watchCallConnectOnlineCulture from './lesson/connectOnlineCulture/saga';

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* rootSaga() {
  yield all([
    watchCallLogin(),
    watchCallCommon(),
    watchCallPasswordUpdate(),
    watchCallResetPassword(),
    watchCallLessonList(),
    watchCallLessonDetail(),
    watchCallConnectOnlineCulture(),
  ]);
}
