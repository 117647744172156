// region Type
export const ACTION_TYPE = {
  CALL_API_CHANGE_PASSWORD: 'CALL_API_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
};
// endregion

// region action
export const actCallApiChangePassword = (param) => ({
  type: ACTION_TYPE.CALL_API_CHANGE_PASSWORD,
  param,
});

export const actChangePasswordSuccess = () => ({
  type: ACTION_TYPE.CHANGE_PASSWORD_SUCCESS,
});
// endregion
