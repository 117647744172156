import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import redirectLinks from '../../../constants/redirectLinks';
import FormErrorText from '../../../components/atoms/formErrorText/FormErrorText';
import {
  actCallApiForgotEmail, actResetPasswordResetFlg,
} from '../../../redux/other/resetPassword/action';
import elcLogo from '../../../public/assets/elcLogo.png';

// #region css
const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(10),
  },
  boxRoot: {
    maxWidth: '530px',
    margin: '60px auto 40px',
    boxSizing: 'border-box',
    padding: '20px',
    border: '2px solid #d9d9d9',
    borderRadius: '10px',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  avatar: {
    width: '100%',
  },
});
// #endregion

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailErrorMessage: '',
      code: '',
      codeErrorMessage: '',
      isComplete: false,
      isError: false,
    };
    this.props.dispatch(actResetPasswordResetFlg());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetPassword !== this.props.resetPassword) {
      if (this.props.resetPassword.isForgotEmailSuccess) {
        this.setComplete();
      }
      if (prevProps.resetPassword.isResetPasswordError
          !== this.props.resetPassword.isResetPasswordError) {
        if (this.props.resetPassword.isResetPasswordError) {
          this.setError();
        }
      }
    }
  }

  onSendClick = () => {
    const { email, code } = this.state;
    const emailErrorMessage = this.validation(email, true);
    const codeErrorMessage = this.validation(code, false);
    if (!emailErrorMessage && !codeErrorMessage) {
      this.props.dispatch(actCallApiForgotEmail({ userType: '2', email, code }));
    }

    this.setState({
      emailErrorMessage,
      codeErrorMessage,
    });
  }

  onChange = (event) => {
    const { name, value } = event.target;
    const { emailErrorMessage, codeErrorMessage } = this.state;
    let emailMessage = emailErrorMessage;
    let codeMessage = codeErrorMessage;

    if (name === 'email') {
      emailMessage = this.validation(value, true);
    } else {
      codeMessage = this.validation(value, false);
    }
    this.setState({
      [name]: value,
      emailErrorMessage: emailMessage,
      codeErrorMessage: codeMessage,
    });
  }

  onTopClick = () => {
    window.location.href = redirectLinks.LOGIN;
  }

  setComplete() {
    this.setState({ isComplete: true });
  }

  setError() {
    this.setState({ isComplete: true, isError: true });
  }

  validation = (value, isEmail) => {
    if (!value) return '入力してください';
    if (isEmail) {
      const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!regex.test(value)) return '正しい形式でメールアドレスを入力してください';
    }
    return '';
  };

  render() {
    const { classes } = this.props;
    const {
      email, emailErrorMessage,
      code, codeErrorMessage,
      isComplete,
      isError,
    } = this.state;

    return (
      <div className={classes.root}>
        <Container className={classes.boxRoot}>
          <Box className={classes.avatar} mb={3}>
            <img src={elcLogo} alt="logo" width="100%" />
          </Box>
          <Box display={isComplete ? 'none' : ''}>
            <Box>
              <Alert severity="info">
                <Box>
                  店舗にご報告いただいたメールアドレスにパスワード再設定ページへのアドレスを含むメールを送信いたします。
                </Box>
                <Box mt={1}>
                  メールをご報告いただいていない場合、メール送信が行えないため、恐れ入りますが、店舗にパスワードをリセットしたい旨をお問合せください。
                </Box>
              </Alert>
            </Box>
            <Box mt={3}>
              <Box fontWeight="fontWeightBold">先生コード</Box>
              <TextField
                id="code"
                variant="outlined"
                autoComplete="off"
                placeholder="先生コード"
                fullWidth
                name="code"
                onChange={this.onChange}
                value={code}
              />
              <FormErrorText>{codeErrorMessage}</FormErrorText>
            </Box>
            <Box mt={3}>
              <Box fontWeight="fontWeightBold">メールアドレス</Box>
              <TextField
                id="email"
                autoComplete="off"
                name="email"
                variant="outlined"
                placeholder="メールアドレス"
                fullWidth
                onChange={this.onChange}
                value={email}
              />
              <FormErrorText>{emailErrorMessage}</FormErrorText>
            </Box>
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onSendClick}
              >
                送信
              </Button>
            </Box>
            <Box textAlign="center" mt={3}>
              <Link href={redirectLinks.LOGIN} variant="body2">
                ログイン画面へ戻る
              </Link>
            </Box>
          </Box>
          <Box display={isComplete ? '' : 'none'}>
            <Box display={!isError ? '' : 'none'}>
              <Box>
                ご登録されたメールアドレスにパスワード再設定のご案内をお送りしました。
              </Box>
              <Box>
                メール本文にあるリンクをクリックしてパスワードを設定してください。
              </Box>
              <Box>
                パスワード再設定のリンクは、24時間で無効になります。
              </Box>
              <Box>
                メールを確認後、すぐにお手続きをしてください。
              </Box>
            </Box>
            <Box display={isError ? '' : 'none'} textAlign="center">
              <Box>恐れ入ります。予期せぬエラーが発生しました。</Box>
              <Box>ログイン画面に戻り、再度お手続きをしてください。</Box>
            </Box>
            <Box textAlign="center" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onTopClick}
              >
                ログイン画面に戻る
              </Button>
            </Box>
          </Box>
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.vertex-group.co.jp/">VERTEX</Link>
              {' '}
              All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ resetPassword: state.resetPassword });

export default withStyles(styles)(withRouter(connect(mapStateToProps)(ForgotPassword)));
