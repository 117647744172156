import { call, put, takeLatest } from 'redux-saga/effects';
import { sendForgotEmail, checkExpiration, resetPassword } from '../../../services/other/resetPassword.service';
import {
  ACTION_TYPE, actForgotEmailSuccess,
  actCheckExpirationSuccess,
  actResetPasswordSuccess,
  actResetPasswordError,
} from './action';

function* callApiForgotEmail(param) {
  try {
    yield call(sendForgotEmail, param.param);
    yield put(actForgotEmailSuccess());
  } catch (error) {
    yield put(actResetPasswordError());
  }
}

function* callApiCheckExpiration(param) {
  try {
    const result = yield call(checkExpiration, param.param);
    yield put(actCheckExpirationSuccess(result));
  } catch (error) {
    yield put(actResetPasswordError());
  }
}

function* callApiResetPassword(param) {
  try {
    yield call(resetPassword, param.param);
    yield put(actResetPasswordSuccess());
  } catch (error) {
    yield put(actResetPasswordError());
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallResetPassword() {
  yield takeLatest(ACTION_TYPE.CALL_API_FORGOT_EMAIL, callApiForgotEmail);
  yield takeLatest(ACTION_TYPE.CALL_API_CHECK_EXPIRATION, callApiCheckExpiration);
  yield takeLatest(ACTION_TYPE.CALL_API_RESET_PASSWORD, callApiResetPassword);
}
