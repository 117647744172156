const redirectLinks = {
  BASE: '/',
  CHANGE_PASSWORD: '/change-password',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  LESSON_DETAIL: '/lesson-detail',
  CONNECT_ONLINE_CULTURE: '/connect-online-culture',
  SYSTEM_ERROR: '/error',
};
export default redirectLinks;
