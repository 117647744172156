import { call, put, takeLatest } from 'redux-saga/effects';
import getMe from '../../services/common/common.service';
import {
  ACTION_TYPE, actGetMeError, actGetMeSuccess,
} from './action';

function* callApiGetMe() {
  try {
    const result = yield call(getMe);
    yield put(actGetMeSuccess(result));
  } catch (error) {
    yield put(actGetMeError(error));
  }
}

/**
 * Export default root saga
 * @returns {IterableIterator<boolean>}
 */
export default function* watchCallCommon() {
  yield takeLatest(ACTION_TYPE.CALL_API_GET_ME, callApiGetMe);
}
